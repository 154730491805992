var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "profile-webinars radius-15 pa-2",
      attrs: { elevation: "3" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.webinars.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          )
        ],
        1
      ),
      _vm.showList
        ? _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "align-center": "",
                "gap-xs-2": "",
                "mt-2": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("p", { staticClass: "starling-body" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("app.profile.webinars.header_webinars")
                            )
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs4: "", "text-center": "" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "starling-body StarlingPrimary2--text"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("app.profile.webinars.header_registered")
                              )
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.webinars, function(webinar) {
                return _c(
                  "v-flex",
                  { key: webinar.id, attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", "align-center": "" } },
                      [
                        _c("v-flex", { attrs: { xs8: "" } }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "starling-text StarlingLightGrey--text"
                            },
                            [_vm._v(_vm._s(webinar.topic))]
                          )
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs4: "" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 justify-center",
                              attrs: {
                                loading: _vm.loading,
                                "hide-details": ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleRegistrationClick(webinar)
                                }
                              },
                              model: {
                                value: webinar.isRegistered,
                                callback: function($$v) {
                                  _vm.$set(webinar, "isRegistered", $$v)
                                },
                                expression: "webinar.isRegistered"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.showErrorMessage
                ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
                    _c("p", {
                      staticClass:
                        "starling-text StarlingPrimary1--text text-center",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "app.products.element.webinar_registration.error_message"
                          )
                        )
                      }
                    })
                  ])
                : _vm._e()
            ],
            2
          )
        : _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("p", { staticClass: "starling-body" }, [
                  _vm._v(_vm._s(_vm.$t("app.profile.webinars.header_webinars")))
                ])
              ]),
              _c("v-flex", { attrs: { xs12: "", "mt-2": "" } }, [
                _c(
                  "p",
                  { staticClass: "starling-text StarlingLightGrey--text" },
                  [_vm._v(_vm._s(_vm.$t("app.profile.webinars.no_items")))]
                )
              ])
            ],
            1
          ),
      _c("custom-confirmation-modal", {
        attrs: {
          display: _vm.showConfirmation,
          title: _vm.$t(
            "app.products.element.webinar_registration.confirmation.title"
          ),
          description: _vm.$t(
            "app.products.element.webinar_registration.confirmation.description"
          ),
          primaryConfirmLabel: _vm.$t("common.actions.delete"),
          secondaryCancelLabel: _vm.$t("common.actions.cancel")
        },
        on: {
          "cancel-event": _vm.onConfirmCancel,
          "confirm-event": _vm.onConfirmDelete
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }